const infoWindowTech = (orden, aviso) => {
    return `<strong>
                <span style="color: #F64E60; font-weight: bold;">Num de OT :</span> ${orden.num_ot} <br> 
                <span style="color: #F64E60; font-weight: bold;">Observación de OT :</span> ${orden.observacion} <br>
                <span style="color: #F64E60; font-weight: bold;">Estado de OT : <i class="${orden.estado_o_t_s ? orden.estado_o_t_s.icono : ''}"></i></span> ${orden.estado_o_t_s ? orden.estado_o_t_s.descripcion : ''}<br> <hr>
                <span style="color: #F64E60; font-weight: bold;">N° Orden :</span> ${aviso.num_aviso} <br> 
                <span style="color: #F64E60; font-weight: bold;">Tipo de aviso :</span> ${aviso.tipo_aviso} <br> 
                <span style="color: #F64E60; font-weight: bold;">Sector :</span> ${aviso.sector} <br> 
                <span style="color: #F64E60; font-weight: bold;">Subestación :</span> ${aviso.subestacion} <br> 
                <span style="color: #F64E60; font-weight: bold;">Descripción:</span> ${aviso.observacion} <br> 
                <span style="color: #F64E60; font-weight: bold;">Referencia:</span> ${aviso.dir_ref} <br> 
                <span style="color: #F64E60; font-weight: bold;">Dirección:</span> ${aviso.dir_suscriptor}
            </strong>`
}
export default infoWindowTech